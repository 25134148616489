$bp-sm: '(max-width: 640px)';
$bp-lg: '(min-width: 641px)';
$bp-tb: '(max-width: 768px)';
$bp-lg-x: '(min-width: 769px)';
.clearfix:after {
  content: "";
  clear: both;
  display: block;
}
@media #{$bp-tb} {
  * {
    box-sizing: border-box;
  }
}
img {
  max-width: 100%;
}

.ico-arrow {
  position: relative;
  &-right {
    position: absolute;
    margin-right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 8px;
    border-color: transparent transparent transparent #fff;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    &--black {
      @extend .ico-arrow-right;
      border-left-color: #595757;
    }
  }
}
div#carousel-control {
  width: 100%;
  max-width: 960px;
  @media #{$bp-tb} {
    display: none;
  }
}
.carousel {
  @media #{$bp-tb} {
    height: 38.2vw;
  }
}

.bg {
  &-white {
    background: #FFF;
  }
  &-light-blue {
    background: #80c9fc;
  }
}
#mini-header,
#background-header,
header#push {
  display: none;
}

.header {
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 0 !important;
  background: #1d4588;
  @media #{$bp-tb} {
    height: 12.52vw;
  }
  &-logo {
    &__img {
      @media #{$bp-lg-x} {
        position:fixed;
        right:95%;
        width:87px;
        height:30px;
        margin-right:-87px;
        z-index:1600;
      }
      @media #{$bp-tb} {
        position: static;
        float: none;
        width: 14.52vw;
        margin-left: auto;
        padding: 0;
      }
    }
  }
  &-nav {
    @media #{$bp-tb} {
      padding: 3.68vw 0 3.84vw 4.16vw;
    }
    &__pc {
      @media #{$bp-tb} {
        display: none !important;
      }
    }
    &__sp {
      display: none;
      @media #{$bp-tb} {
        display: block;
        
      }
    }
    &-trigger {
      @media #{$bp-lg-x} {
        display: none;
      }
      @media #{$bp-tb} {
        position: absolute;
        top: 2.44vw;
        right: 3vw;
        width: 7.36vw;
        height: 7.36vw;
        cursor: pointer;
      }
    }
  }
  &-gnav {
    @media #{$bp-tb} {
      position: absolute;
      top: 100%;
      right: 0;
      width: 80%;
      height: 93vh;
      background: rgba(0,29,77,.9);
      transform: translateX(100%);
      transition: .3s;
      @at-root .nav-open & {
        transform: translateX(0);
      }
      &__item {
        border-bottom: 1px solid #FFF;
      }
      &__link {
        display: block;
        padding: 7vw 3vw;
        color: #FFF;
      }
    }
  }
  div#header-nav_panel {
    @media #{$bp-tb} {
      display: none !important;
    }
  }
}

.top {
  margin-top: 52px;
  @media #{$bp-tb} {
    margin-top: 12.52vw;
  }
  &-btn-more {
    padding-left: 15px;
    position: absolute;
    right: 0;
    top: 0;
    color: #FFF;
    font-size: 1.2rem;
    &:hover {
      text-decoration: underline;
    }
    &--black {
      @extend .top-btn-more;
      color: #595757;
    }
    @media #{$bp-tb} {
      display: none;
    }
  }
  &-section-box {
    padding: 30px 0;
    @media #{$bp-tb} {
      padding: 5% 0;
    }
  }
  &-cmn-ttl {
    position: relative;
    text-align: center;
    font-size: 2rem;
    padding-bottom: 30px;
  }
  .inner {
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    @media #{$bp-tb} {
      padding: {
        left: 4.2%;
        right: 4.2%;
      };
    }
  }
  &-products {
    color: #FFF;
    &__body {
      color: #fff;
    }
    &-list {
      @media #{$bp-lg-x} {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-wrap: wrap;
      }
      &__items {
        text-align: center;
        @media #{$bp-lg-x} {
          width: 31%;
          float: left;
        }
        &:nth-child(n+2) {
          @media #{$bp-lg-x} {
            margin-left: 3.5%;
          }
          @media #{$bp-tb} {
            margin-top: 3.7%;
          }
        }
        &:nth-child(n+4) {
          @media #{$bp-lg-x} {
            margin-top: 20px;
          }
        }
        &:nth-child(4) {
          @media #{$bp-lg-x} {
            margin-left: 0;
          }
        }
      }
    }
    &__ttl {
      display: block;
      margin: 10px 0 10px;
      padding: 5px 0;
      line-height: 1;
      &.line-color-red {
        background: #ca3234;
      }
      &.line-color-purple {
        background: #a63e92;
      }
      &.line-color-orange {
        background: #ef845d;
      }
      &.line-color-l-green {
        background: #abcd0b;
      }
      &.line-color-blue {
        background: #1298ff;
      }
      &.line-color-gray {
        background: #727171;
      }
    }
    &__link {
      color: #FFF;
      &:hover {
        color: #FFF;
        text-decoration: underline;
      }
    }
    &__lead {
      font-size: 1.2rem;
    }
  }
  &-news {
    &__ttl {
      padding-bottom: 10px;
      border-bottom: 2px solid #1d4588;
      color: #1d4588;
      text-align: left;
      &-ja {
        display: inline-block;
        margin-left: 20px;
        font-size: 1.3rem;
        @media #{$bp-tb} {
          font-size: 1.0rem;
        }
      }
    }
    &-cate {
      padding: 5px;
      border-radius: 3px;
      background-color: #1d4588;
      color: #FFF;
      text-align: center;
      text-align: center;
      &--green {
        @extend .top-news-cate;
        background: #80c638;
      }
      &--blue {
        @extend .top-news-cate;
        background: #3e9aff;
      }
      &--orange {
        @extend .top-news-cate;
        background: #f18e38;
      }
    }
    &__header {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      margin-top: 20px;
      @media #{$bp-tb} {
        justify-content: space-between;
      }
      &-items {
        cursor: pointer;
        @media #{$bp-lg-x} {
          flex-basis: 95px;
          max-width: 95px;
          font-size: 1.2rem;
          & + & {
            margin-left: 10px;
          }
        }
        @media #{$bp-tb} {
          flex-basis: 24%;
          max-width: 24%;
          font-size: 1.0rem;
        }
      }
    }
    &-list {
      margin-top: 20px;
      &__item {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        align-items: center;
        @media #{$bp-tb} {
          flex-wrap: wrap;
        }
        & + & {
          margin-top: 5px;
          padding-top: 5px;
          border-top: 1px dashed #1d4588;
          @media #{$bp-tb} {
            margin-top: 10px;
            padding-top: 10px;
          }
        }
      }
      &__date {
        margin-right: 12px;
        font-size: 1.5rem;
      }
      &__cate {
        flex-basis: 75px;
        max-width: 75px;
        margin-right: 15px;
        font-size: .8rem;
        @media #{$bp-tb} {
          flex-basis: 100px;
          max-width: 100px;
          padding: 3px;
        }
      }
      &__ttl {
        color: #525252;
        font-size: 1.2rem;
        @media #{$bp-tb} {
          flex-basis: 100%;
          max-width: 100%;
          margin-top: 5px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  &-btn {
    padding-top: 0;
    &-list {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      text-align: center;
      &__item {
        flex-basis: 31%;
        max-width: 31%;
        & + & {
          margin-left: 3.5%;
        }
      }
    }
  }
  &-seminar {
    color: #000;
    &__ttl {
      color: #FFF;
    }
    &-list {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      @media #{$bp-tb} {
        flex-wrap: wrap;
      }
      &__item {
        flex-basis: 23.5%;
        max-width: 23.5%;
        @media #{$bp-tb} {
          flex-basis: 48%;
          max-width: 48%;
          &:nth-child(n + 3) {
            margin-top: 20px;
          }
        }
        @media #{$bp-lg-x} {
          & + & {
            margin-left: 2%;
          }
        }
        a {
          display: block;
          color: #000;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &__ttl {
        margin-bottom: 5px;
        font-size: 1.2rem;
      }
      &__img {
        border: 2px solid #FFF;
      }
      &__caption {
        margin-top: 10px;
        font-size: .8rem;
        text-align: center;
      }
    }
  }
}
.footer {
  @media #{$bp-tb} {
    background: #98938f;
  }
  &-pc {
    display: block;
    @media #{$bp-tb} {
      display: none;
    }
  }
  &-sp {
    display: none;
    @media #{$bp-tb} {
      display: block;
      padding: {
        top: 2.22%;
        bottom: 2.22%;
      };
      color: #FFF;
      line-height: 1.5;
    }
  }
  &-copyright {
    @media #{$bp-tb} {
      margin-top: 10px;
    }
  }
}
